/* eslint-disable @typescript-eslint/no-unused-vars */
import routes from "./routes/Routes";
import { useRoutes } from "react-router-dom";
import "./app.css";
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import crossBrowserListener from "./utils/redux-persist-listner";
import storage from "redux-persist/lib/storage";
import hardSet from "redux-persist/lib/stateReconciler/hardSet";
import { PersistGate } from "redux-persist/integration/react";
import store from "./state/store";
import { HelmetProvider } from "react-helmet-async";

const App = () => {
  const helmetContext = {};
  const persistConfig = {
    key: "root",
    timeout: 100,
    storage,
    stateReconciler: hardSet,
  };
  const persistor = persistStore(store);
  store.persistor = persistor;

  window.addEventListener(
    "storage",
    crossBrowserListener(store, persistConfig)
  );
  const content = useRoutes(routes);
  // const AppRouter = (() => {
  //   const content = useRoutes(routes);
  //   return content;
  // })
  return (
    <>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          {/* <AppRouter></AppRouter> */}
          <HelmetProvider context={helmetContext}>{content}</HelmetProvider>
        </PersistGate>
      </Provider>
    </>
  );
};
export default App;
