const RoutingLinks = {
  website: {
    index: '/',
    home: '/home',
    about: '/about',
    contact: '/contact',
    team: '/team',
    features: '/features',
    products: '/products',
    productsList: {
      erp: 'https://erp.tej.life',
      ecom: 'https://ecom.tej.life'
    },
    ai: '/ai',
    teamMembers: {
      yogesh: '/team/yogeshnogia',
      shobhit: '/team/shobhit',
      sajan: '/team/sajan',
      dhruv: '/team/dhruv',
      mukul: '/team/mukul',
      manish: 'team/manish',
      prashant: '/team/prashant',
      neha: '/team/neha'
    },
    careers: '/careers',
    jobPostings: {
      juniorNodeJs: '/careers/junior-nodejs-developer-position-at-tej-life',
      juniorGo: '/careers/junior-go-developer-position-at-tej-life',
      juniorReact: '/careers/junior-reactjs-developer-position-at-tej-life',
      seniorNodeJs: '/careers/senior-nodejs-developer-position-at-tej-life',
      seniorGo: '/careers/senior-go-developer-position-at-tej-life',
      seniorReact: '/careers/senior-reactjs-developer-position-at-tej-life',
      seniorPython: '/careers/senior-python-developer-position-at-tej-life',
      awsDevops: '/careers/lead-aws-dev-ops-position-at-tej-life',
      businessAnalyst: '/careers/business-analyst-position-at-tej-life',
      socialMediaManager: '/careers/social-media-manager-position-at-tej-life',
      uiDesigner: '/careers/ui-ux-designer-position-at-tej-life'
    },
    ourwork: '/projects',
    projects: {
      ypredict: '/projects/ypredict-prediction-marketplace',
      findscan: '/projects/findscan-stock-marketplace',
      artistsPlatform: '/projects/artists-platform',
      legalPlatform: '/projects/legal-platform-for-lawyers',
      xdcwallet: '/projects/xdc-wallet-web3-dapp',
      blockchainTech: '/projects/blockchain-web3-library', //DODODO Page Pending Not visible on footer
      drchatbot: '/projects/dr-chatbot-engagement',
      mithomes: '/projects/real-estate-platform-move-in-today-dot-com',
      ERPsolution:
        '/projects/enterprise-resource-planning-software-for-manufacturing-industry',
      medicalIndustry: '/projects/medical-health-hospital-management-software'
    },
    services: '/services',
    servicesAll: {
      ERPsolutions:
        '/services/erp-solutions-by-tej-life-complete-enterprise-resource-planning-soloution',
      healthCare: '/services/healthcare-industry-solutions-by-tej-life',
      ai: '/services/artificial-intelligence-support-by-tej-life',
      devops: '/services/dev-ops-services-by-tej-life',
      chatai: '/services/chat-ai-services-by-tej-life',
      nlp: '/services/natural-language-processing-training-by-tej-life',
      voiceai: '/services/voice-ai-services-by-tej-life',
      manufacturing:
        '/services/manufacturing-industry-level-software-support-by-tej-life',
      appdev:
        '/services/ios-android-app-development-by-tej-life-complete-app-solutions',
      generativeAi: '/services/generative-ai-system-support-by-tej-life',
      zeroDownTime: '/services/zero-down-time-on-servers',
      securedNetwork: '/services/secured-netwrok-suport',
      uiUx: '/services/eye-pleasing-user-interface-and-user-experience-design-system',
      microServices:
        '/services/micro-services-architecture-design-system-support-by-tej-life',
      techStack: '/services/top-tech-stack-to-support-your-business'
    }
  },
  dashboard: {
    home: '/dashboard/home',
    profile: '/dashboard/profile'
  },
};

export default RoutingLinks;
