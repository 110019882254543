import { Link } from "react-router-dom";
import tejlogo from "../assets/img/tejlife_SVG_logo_2.svg";
import shop from "../assets/img/new/Shop_Bag.png";
import love from "../assets/img/love-letter.png";
import star from "../assets/img/star.png";
import openai from "../assets/img/OpenAI.svg";
import RoutingLinks from "src/utils/RoutingLinks";
import { LinkOutlined } from "@ant-design/icons";
import EmailGet from "src/components/EmailGet";
import SEOTags from "src/utils/SEOTags";
import { PagesMeta } from "src/types/PagesMeta";

const AI = () => {
  return (
    <>
    <SEOTags title={PagesMeta.products.title} description={PagesMeta.products.description} type={PagesMeta.products.type} twitterDes={PagesMeta.products.twitterDes} pageUrl={PagesMeta.products.pageUrl}/>
      <div className="page-wrapper page-wrapper-flex">
        <div
          data-w-id="633c7290-841f-f768-cc10-d7d9aa0a9b21"
          data-animation="default"
          data-collapse="medium"
          data-duration="400"
          data-easing="ease"
          data-easing2="ease"
          role="banner"
          className="header-wrapper w-nav"
        >
          <div className="container-default w-container">
            <div
              data-w-id="633c7290-841f-f768-cc10-d7d9aa0a9b23"
              className="header-content-wrapper flex-horizontal items-baseline"
            >
              <Link to="/" className="header-logo-link center w-nav-brand">
                <img
                  src={tejlogo}
                  alt="Mascru - Logo"
                  className="header-logo !w-16"
                />
              </Link>
              
            </div>
            <h1 className="text-center italic text-xl m-0 mt-10 font-medium no-underline w-full"><Link className="no-underline" to={RoutingLinks.website.products} ><LinkOutlined/> AI Powered Solutions 🚀</Link></h1>
          </div>
        </div>
        <div className="section hero coming-soon-hero">
          <div className="container-default position-relative w-container !overflow-visible">
            <div
              data-w-id="ea94fae7-c17b-caae-7783-1fea3b4d89ab"
              className="shape-wrapper md:style-1"
            >
              <div className="shape size-130px top-left">
                <img src={shop} alt="" className="moving-item-two" />
              </div>
              <div className="shape size-150px bottom-left">
                <img src={love} alt="" className="blur-5px moving-item-two" />
              </div>
              <div className="shape size-150px top-right">
                <img src={star} alt="" className="moving-item-one" />
              </div>
              <div className="shape size-120px bottom-right">
                <img
                  src={openai}
                  alt=""
                  className="blur-1-5px moving-item-one"
                />
              </div>
            </div>
            <div className="text-center">
              <div className="inner-container _580px center">
                <div className="z-index-1">
                  <div
                    data-w-id="deb09750-b7ba-c3f8-81c8-980638151f08"
                    className="md:style-1"
                  >
                    <img
                      src="https://assets.website-files.com/63dbcee746674134a00f2de6/63dd5e7701fb94153dfad7d0_face-with-peeking-eye-cloudly-x-webflow-templates.png"
                      alt=""
                      className="shape position-static size-114px"
                    />
                    <img
                      src="https://assets.website-files.com/63dbcee746674134a00f2de6/63dd5e83ccbf426db687ad63_speak-no-evil-monkey-cloudly-x-webflow-templates.png"
                      alt=""
                      className="shape position-static size-114px"
                    />
                    <img
                      src="https://assets.website-files.com/63dbcee746674134a00f2de6/63dd5e8fbb10486d78f54f05_shushing-face-cloudly-x-webflow-templates.png"
                      alt=""
                      className="shape position-static size-114px"
                    />
                  </div>
                  <div
                    data-w-id="e57eda69-f44d-5edd-2a08-181d4468ccbf"
                    className="md:style-1"
                  >
                    <h1 className="display-1">Launching soon</h1>
                    <p className="mg-bottom-48px">
                      AI- Powered Ecommerce Platform along with ERP Software to boost your sales and revenue 🚀. <br />
                    </p>
                    <EmailGet />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-container">
          <div className="container-default w-container">
            <div
              data-w-id="25818af3-6e01-e5f9-d993-ed23bde7b70a"
              className="footer---bottom v3"
            >
              <a
                id="w-node-_25818af3-6e01-e5f9-d993-ed23bde7b70b-bde7b708"
                href="#"
                className="footer-logo-wrapper mg-bottom-0 w-inline-block"
              >
                <img
                  src={tejlogo}
                  alt="Mascru - Logo"
                  className="footer-logo"
                />
              </a>
              <div>
                <p className="mg-bottom-0 color-neutral-700">
                  Copyright © Mascru | Connect{" "}
                  <Link to="mailto:hey@mascru.com">
                    hey@mascru.com
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AI;
