import React from "react";
import { Helmet } from "react-helmet-async";
import { SEOTagsProps } from "src/types/PagesMeta";
// import TejLifeMetaImage from "../assets/img/tej-metatag.png"

const SEOTags: React.FC<SEOTagsProps> = ({
  title,
  description,
  type,
  twitterDes,
  pageUrl
}) => {
  
  return (
    <>
      <Helmet>
        {/* Standard metadata tags */}
        <title>{title}</title>
        <meta name="description" content={description} />
        {/* End standard metadata tags */}
        {/* Facebook tags */}
        <meta property="og:title" content={title} />
        <meta property="og:description" content={twitterDes} />
        {/* <meta property="og:image" content={TejLifeMetaImage} /> */}
        <meta property="og:url" content={pageUrl} />
        <meta property="og:type" content={type} />
        {/* End Facebook tags */}
        {/* Twitter tags */}
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={twitterDes} />
        {/* <meta name="twitter:image" content={TejLifeMetaImage}/> */}
        <meta name="twitter:card" content={type} />
        <meta name="twitter:site" content="@user" />
        <meta name="twitter:creator" content={"@letstej"} />
        {/* End Twitter tags */}
      </Helmet>
    </>
  );
};

export default SEOTags;
