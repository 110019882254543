import { get, headers } from './ApiConfigindex.ts';
import { BaseSetting } from '../utils/BaseSetting.ts';
const UserApis = {
  /**
   * Get Posts List
   * @returns {} an object{}: data
   */
  getByUserId: async (id: any) => {
    // id = '635acdb6d73dc75ae04a8217'
    try {
      const response = await get(BaseSetting.ApiDomain + `/users/${id}`, {...headers});
      return response?.data;
    } catch (error) {
      return false;
    }
  }
};

export default UserApis;
