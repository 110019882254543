// THIS COMPONENT is for to get the email ids, represent a form and its uses

import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import WebApi from "src/api/WebApi";

interface ComingSoon {
  email: string;
}

const EmailGet = () => {
  const [isSuccess, setSuccess] = useState(false);
  const { register, handleSubmit, reset } = useForm<ComingSoon>();

  const postData = async (data: any) => {
    try {
      const response = await WebApi.comingsoon(data);
      setSuccess(true);
      return response.data;
    } catch (error) {
      console.error("Error posting data:", error);
    }
  };

  const onSubmit: SubmitHandler<ComingSoon> = data => {
    postData(data);
    reset();
  };

  return (
    <>
      <div className="form-container">
        <div className="mg-bottom-16px w-form">
          <form
            id="wf-form-Coming-Soon-Newsletter"
            name="wf-form-Coming-Soon-Newsletter"
            data-name="Coming Soon Newsletter"
            method="get"
            data-wf-page-id="63dd3c718753209766729d2a"
            data-wf-element-id="ea94fae7-c17b-caae-7783-1fea3b4d89ad"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="position-relative">
              <input
                type="email"
                className="input button-inside w-input !border-[#a0a3bd]"
                maxLength={250}
                data-name="Email"
                placeholder="Enter your email address"
                id="Email"
                {...register("email", {
                  required: true,
                  maxLength: 250,
                })}
              />
              <input
                type="submit"
                value="Subscribe Now"
                className="btn-primary inside-input w-button"
              />
            </div>
          </form>
          <div
            className={`success-message ${
              isSuccess ? "!block" : ""
            } w-form-done`}
          >
            <div>
              <div className="line-rounded-icon success-message-check large">
                &#x2705;
              </div>
              <h3 className="color-neutral-800">Thank you</h3>
              <div>
                Your message has been submitted.
                <br />
                We will update you shortly.
              </div>
            </div>
          </div>
          <div className="error-message w-form-fail">
            <div>Oops! Something went wrong while submitting the form.</div>
          </div>
        </div>
      </div>

      <div className="center">
        <div className="icon-list-item-wrapper center left-tablet flex gap-2 !mt-4 !justify-center">
          <div className="mg-right-12px">
            <img
              src="https://assets.website-files.com/63dbcee746674134a00f2de6/63dd5783192bfc553e0aa5cc_envelope-cloudly-x-webflow-templates.png"
              alt=""
              className="image-size _32px"
            />
          </div>
          <div className="text-200 color-neutral-700">
            Subscribe to get the early access
          </div>
        </div>
      </div>
    </>
  );
};

export default EmailGet;
