import { get, headers } from './ApiConfigindex.ts';
import { BaseSetting } from '../utils/BaseSetting.ts';
const PostApis = {
  /**
   * Get Posts List
   * @returns {[]} an array[]: data
   */
  getPosts: async () => {
    try {
      const response = await get(BaseSetting.ApiDomain + `/posts`, {...headers});
      return response?.data;
    } catch (error) {
      return error;
    }
  },
  getPost: async (id: string | undefined) => {
    try {
      const response = await get(BaseSetting.ApiDomain + '/posts/' + id, {...headers});
      return response?.data;
    } catch (error) {
      return false;
    }
  }
};

export default PostApis;
