// Import necessary dependencies
import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';

// Define the ThemeContext and types
interface ThemeContextType {
  theme: string;
  toggleTheme: () => void;
}

export const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

// Create a custom hook for using the theme context
export const useTheme = () => {
    const context = useContext(ThemeContext);
    if (!context) {
        throw new Error('useTheme must be used within a ThemeProvider');
    }
    return context;
};

// Define the ThemeProvider component
interface ThemeProviderProps {
  children: ReactNode;
}

export const ThemeProvider = ({ children }: ThemeProviderProps) => {
    // Read the theme from localStorage or use 'light' as the default
  const initialTheme = localStorage.getItem('theme') || 'light';

  const [theme, setTheme] = useState<string>(initialTheme);

  const root = window.document.documentElement;

  useEffect(() => {
    // Save the theme to localStorage whenever it changes
    root.classList.remove(theme == "dark" ? 'light' : 'dark');
    root.classList.add(theme);
    localStorage.setItem('theme', theme);
  }, [theme]);

    const toggleTheme = () => {
        setTheme(prevTheme => (prevTheme === 'light' ? 'dark' : 'light'));
    };

    return (
        <ThemeContext.Provider value={{ theme, toggleTheme }}>
            {children}
        </ThemeContext.Provider>
    );
};

// Example usage of the ThemeProvider
// Wrap your top-level component in ThemeProvider in your App.tsx or index.tsx
// This ensures that the theme context is available throughout your application
// Example: ReactDOM.render(<ThemeProvider><App /></ThemeProvider>, document.getElementById('root'));