import { headers, post } from './ApiConfigindex.ts';
import { BaseSetting } from '../utils/BaseSetting.ts';
const WebApi = {
  /**
   * Contact us form
   * @param {String} name User Name
   * @param {String} email User Email
   * @param {String} phone User Phone
   * @param {String} subject User Subject
   * @param {String} message User Message
   * @returns {Object} data
   */
  contactForm: async (req: object) => {
    try {
      const response = await post(BaseSetting.webApiDomain + '/contact/post', req, {...headers});
      return response.data;
      // return response.data.status === 1 ? response.data.data : false;
    } catch (error) {
      return false;
    }
  },
  comingsoon: async (req: object) => {
    try {
      const response = await post(BaseSetting.webApiDomain + '/career/post', req, {...headers});
      return response.data;
      // return response.data.status === 1 ? response.data.data : false;
    } catch (error) {
      return false;
    }
  },
}

export default WebApi;