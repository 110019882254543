/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-children-prop */
import { Suspense, lazy } from "react";
import type { RouteObject } from "react-router";
import LoadingScreen from "../components/LoadingScreen";
import MainLayout from "../layouts/MainLayout";
// import AuthGuard from '../components/Authentication/AuthGuard';
import GuestGuard from "../components/Authentication/GuestGuard";
import AuthGuard from "../components/Authentication/AuthGuard";
import Posts from "src/pages/Posts";
import Post from "src/pages/Post";
import NotFound from "src/pages/NotFound";
import ComingSoon from "src/pages/ComingSoon";
import Products from "src/pages/Products";
import OurTeam from "src/components/OurTeam";
import AI from "src/pages/ai";


// eslint-disable-next-line react/display-name
const Loadable = (Component: any) => (props: JSX.IntrinsicAttributes) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <>
        <Component {...props} />
      </>
    </Suspense>
  );

// *  AUTHENTICATION PAGES
const Login = Loadable(lazy(() => import("../pages/Authentication/Login")));
const Register = Loadable(
  lazy(() => import("../pages/Authentication/Register"))
);



//  * Pages Import
const Home = Loadable(lazy(() => import("../pages/Home")));
const About = Loadable(lazy(() => import("../pages/About")));
const Contact = Loadable(lazy(() => import("../pages/Contact")));
const Careers = Loadable(lazy(() => import("../pages/Careers")));
const TeamMember = Loadable(lazy(() => import("../pages/SingleTeamMember")));
const SingleJobPost = Loadable(lazy(() => import("../pages/SingleJobPost")));
const SingleTechnology = Loadable(lazy(() => import("../pages/SingleTechnology")));
const OurWork = Loadable(lazy(() => import("../pages/OurWork")));
const SingleProject = Loadable(lazy(() => import("../pages/SingleProject")));
const Services = Loadable(lazy(() => import("../pages/Services")));
const SingleService = Loadable(lazy(() => import("../pages/SingleService")));


const routes: RouteObject[] = [
  //These are the normal routes which are visible to everyone
  {
    path: "*",
    element: <MainLayout />,
    children: [
      {
        index: true,
        path: "",
        element: <Home></Home>
      },
      {
        path: "home",
        element: <Home></Home>
      },
      {
        path: "about",
        element: <About></About>
      },
      {
        path: "contact",
        element: <Contact></Contact>
      },
      {
        path: "careers",
        element: <Careers></Careers>
      },
      {
        path: "team",
        element: <OurTeam />
      },
      {
        path: "team/:id",
        element: <TeamMember />
      },
      {
        path: "careers/:id",
        element: <SingleJobPost />
      },
      // {
      //   path: "technologies",
      //   element: <Technologies />
      // },
      {
        path: "technology/:id",
        element: <SingleTechnology/>
      },
      {
        path: "projects",
        element: <OurWork />
      },
      {
        path: "projects/:id",
        element: <SingleProject/>
      },
      {
        path: "services",
        element: <Services />
      },
      {
        path: "products",
        element: <Products />
      },
      {
        path: "services/:id",
        element: <SingleService/>
      },
      {
        path: "posts",
        children: [
          {
            index: true,
            path: "all",
            element: <Posts />
          }
        ]
      },
      {
        path: "post/:id",
        element: <Post />
      },
      {
        path: "users/:id",
        element: <Post />
      },
      {
        path: "*", //It will redirect every path to 404 page if no route matches to above paths
        element: <NotFound></NotFound>
      }
    ]
  },
  {
    path: "coming-soon",
    element: <ComingSoon></ComingSoon>
  },
  {
    path: "ai",
    element: <AI></AI>
  },
  {
    path: "authentication",
    children: [
      {
        index: true,
        path: "",
        element: <AuthGuard children={undefined}></AuthGuard>
      },
      {
        path: "login",
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        )
      },
      {
        path: "register",
        element: (
          <GuestGuard>
            <Register />{" "}
          </GuestGuard>
        )
      }
    ]
  },

  //Checking if the user is logged in through AuthGuard, if Authguard approves, then only we show the component, otherwise Authguard redirects
  //back to login page
  {
    path: "dashboard",
    children: [
      {
        index: true,
        path: "",
        element: <AuthGuard children={undefined}></AuthGuard>
      },
      {
        path: "profile",
        element: (
          <AuthGuard>
            <h1>Profile Section Component</h1>
          </AuthGuard>
        )
      },
      {
        path: "settings",
        element: (
          <AuthGuard>
            <h1>Settings Component</h1>
          </AuthGuard>
        )
      }
    ]
  }
];

export default routes;
