/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-var */
import { ADD_ROW, UPDATE_ROW, DELETE_ROW } from './Actions';

const initialState = { data: [] };

const Reducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case ADD_ROW:
      return {
        ...state,

        data: [
          ...state.data,
          {
            ...action.payload,
            id: state.data.length + 1
          }
        ]
      };

    default:
      break;
  }
  return state;
};

export default Reducer;
