import { getStoredState, REHYDRATE } from 'redux-persist';
import { PersistConfig } from 'redux-persist/es/types';

export default function crossBrowserListener(
  store: {
    dispatch: (arg0: {
      type: 'persist/REHYDRATE';
      key: any;
      payload: object | undefined;
    }) => void;
  },
  persistConfig: PersistConfig<any, any, any, any>
) {
  return async function () {
    const state = await getStoredState(persistConfig);

    store.dispatch({
      type: REHYDRATE,
      key: persistConfig.key,
      payload: state
    });
  };
}
