/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import { Link } from 'react-router-dom';

const PostList = (posts:any) => {
  return (
    posts.posts.map((item:any) => {
      return <div key={item.id}>
        <Link to={`/post/${item.id}`}> <h1 className='text-2xl text-blue-600'>Title: {item?.title}</h1></Link>
        <Link to={`/user/${item.userId}`}> <h1 className='text-base text-blue-600'>{item?.userId}</h1></Link>
      </div>
    })
  )
}

export default PostList;