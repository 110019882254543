/* eslint-disable @typescript-eslint/no-unused-vars */
import { useQuery } from "react-query";
import { Link, useParams } from "react-router-dom";
import PostApis from "src/api/Posts";
import UserApis from "src/api/User";
import LoadingScreen from "src/components/LoadingScreen";

const Post = () => {
  const { id } = useParams();
  const {
    status: statusPost,
    error: errorPost,
    data: post
  } = useQuery({
    queryKey: ["post", parseInt(id!)],
    queryFn: () => PostApis.getPost(id!)
  })

  const {
    status: statusUser,
    data: user,
    error: errorUser
  } = useQuery({
    enabled: post?.userId != null,
    queryKey: ["user", post?.userId],
    queryFn: () => UserApis.getByUserId(post!.userId)
  })


  if(statusPost === "loading")  return <LoadingScreen/>
  if(statusPost === "error")    return <h1>{JSON.stringify(errorPost)}</h1>

  if(statusUser === "loading") return <LoadingScreen />
  if(statusUser === "error") return <h1>{JSON.stringify(errorUser)}</h1>



  return (
    <>
        <div>
          <h1 className="text-xl">Single Post Page</h1>
            <Link to="/posts/all" className="text-blue-600">Go Back to all Posts</Link>
            <h1 className="text-2xl">Title: {post.title}</h1>
            
                <Link to={`/users/${post.userId}`}>
                    <p className="text-blue-600">Username: {statusUser === "success" && user.firstName}</p>
                </Link>
                <br/>
            {post.body}
        </div>
    </>
  )
};

export default Post;
