import { useEffect } from 'react';
import type { FC } from 'react';
// import NProgress from 'react-nprogress';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

const LoadingScreen: FC = () => {
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  useEffect(() => {
    // NProgress.start();
    return (): void => {
    //   NProgress.done();
    };
  }, []);

  return (
    <Spin indicator={antIcon} />
  );
};

export default LoadingScreen;
