import type { FC, ReactNode } from "react";
import PropTypes from "prop-types";
import { Link, Outlet } from "react-router-dom";
import NavBarMain from "src/components/Navigation/NavBarMain";
import FooterMain from "src/components/Footer/FooterMain";
// import RoutingLinks from "src/utils/RoutingLinks";
// import experimentalStyled from '@mui/styled-engine';

interface MainLayoutProps {
  children?: ReactNode;
}

// const MainLayoutRoot = experimentalStyled('div')(({ theme }: any) => ({
//   backgroundColor: theme.palette.background.default,
//   height: '100%',
//   paddingTop: 64,
// }));

const TopBar = () => {
  return (
    <>
      <div className="bg-[#1d1a2e] h-auto p-2 flex justify-center fixed top-0 w-full z-20">
        <div className="text-white text-center">
          <Link
            to="https://kartlify.com"
            target="_blank"
            className="text-white text-sm md:text-base hover:underline hover:text-white"
          >
            Hurry!🎉🥳 Mascru has launched Kartlify. Go Visit 🚀{" "}
          </Link>
        </div>
      </div>
    </>
  );
};

const MainLayout: FC<MainLayoutProps> = ({ children }) => {
  return (
    <>
      <div className="page-wrapper">
        {/* <div className="position-relative bg-gradient---hero"> */}
        <TopBar />
        <NavBarMain />

        <div className="md:mt-32 mt-24">{children || <Outlet />}</div>
        <FooterMain />
        {/* </div> */}
      </div>
      {/* <SmallNavBar /> */}
    </>
  );
};

MainLayout.propTypes = { children: PropTypes.node };

export default MainLayout;
