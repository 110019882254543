import RoutingLinks from "src/utils/RoutingLinks";

//People images
import yogesh from "../assets/img/yogeshNew.jpg";
import shobhit from "../assets/img/shobhit.png";
// import bettercallmanish from "../assets/img/manish.png";
import dhruv from "../assets/img/dhruv2.png";
import prashant from "../assets/img/prashant-sagar.png";
import neha from "../assets/img/neha-nogia.png";
import mukul from "../assets/img/mukul.png";

interface TeamInfo {
  id: number;
  name: string;
  link: string;
  username: string; // this username is being used for search inside the components, so be careful with this one.
  position: string;
  img: string;
  bgColor: string;
  description: string;
  social: {
    facebook: string;
    twitter: string;
    instagram: string;
    linkedin: string;
    github: string;
  };
}

const TeamMembersInfo: TeamInfo[] = [
  {
    id: 1,
    username: "yogeshnogia",
    name: "Yogesh Nogia",
    link: RoutingLinks.website.teamMembers.yogesh,
    position: "Founder & CEO",
    img: yogesh,
    bgColor: "#fddae5",
    description: `Our founder, with a background in Computer Science, embarked on an entrepreneurial journey early in their career by establishing their first startup, 'LoopStores,' during their second year of college. Afterward, they honed their skills and knowledge as a Senior Tech Analyst at Merkle Inc. \r\n Drawing from this rich experience and expertise, they have successfully founded and led our tech company, positioning it at the forefront of the industry. Their dynamic journey from a college entrepreneur to a tech industry leader is a testament to their visionary leadership and unwavering commitment to innovation.`,
    social: {
      facebook: "",
      twitter: "",
      instagram: "",
      linkedin: "",
      github: "",
    },
  },
  {
    id: 2,
    username: "prashant",
    name: "Prashant Sagar",
    link: RoutingLinks.website.teamMembers.prashant,
    position: "Investor ",
    img: prashant,
    bgColor: "#fddae5",
    description: `As an investor in our company, Prashant Sagar has been a driving force behind our success. His insights and guidance have been invaluable, particularly in aligning our business strategies with emerging technological trends. Prashant Sagar is a dynamic and influential figure in the corporate and investment landscapes. His contributions to Bajaj and his strategic investments in tech and manufacturing sectors exemplify his prowess as a smart, forward-thinking leader and investor.`,
    social: {
      facebook: "",
      twitter: "",
      instagram: "",
      linkedin: "",
      github: "",
    },
  },
  {
    id: 3,
    username: "neha",
    name: "Neha Nogia",
    link: RoutingLinks.website.teamMembers.neha,
    position: "Investor",
    img: neha,
    bgColor: "#cfcffe",
    description:
      "IIT Mumbai Graduate, MS from USA. Neha's foray into the world of investment, particularly with our company, Tej.Life, has been marked by her smart and forward-thinking approach. She brings a unique blend of technical knowledge, global experience, and business acumen to the table, making her investment decisions both insightful and impactful.",
    social: {
      facebook: "",
      twitter: "",
      instagram: "",
      linkedin: "",
      github: "",
    },
  },
  {
    id: 4,
    username: "shobhit",
    name: "Shobhit Agarwal",
    link: RoutingLinks.website.teamMembers.shobhit,
    position: "CTO",
    img: shobhit,
    bgColor: "#fddae5",
    description:
      "With a background of CS in Engineering, Ex-Tech Lead at Simplify, Product Developer, Pioneering the Future as CTO of a Cutting-Edge Technology Service Provider In the realm of cutting-edge technology and innovation, there exists a visionary leader who stands at the helm of a dynamic tech team, steering the ship toward new horizons. Meet Shobhit Agarwal, the Chief Technology Officer (CTO) of a groundbreaking technology service provider, renowned for its unwavering commitment to pushing the boundaries of what`s possible.",
    social: {
      facebook: "",
      twitter: "",
      instagram: "",
      linkedin: "",
      github: "",
    },
  },
  {
    id: 5,
    username: "dhruv",
    name: "Dhruv Singh",
    link: RoutingLinks.website.teamMembers.dhruv,
    position: "VP of UI/UX",
    img: dhruv,
    bgColor: "#fbe2d9",
    description:
      "Ex-Head of UI/UX Designing at WittyFeed. Dhruv takes on the crucial roles of both Product Manager and Designer, a rare combination that allows him to connect innovation with practicality.",
    social: {
      facebook: "",
      twitter: "",
      instagram: "",
      linkedin: "",
      github: "",
    },
  },
  {
    id: 6,
    username: "mukul",
    name: "Mukul Choudhary",
    link: RoutingLinks.website.teamMembers.mukul,
    position: "CMO",
    img: mukul,
    bgColor: "#d6f7de",
    description:
      `A digital marketing maven with a flair for hacking the online world and making things go viral. Behind the computer screen, he's a mastermind who understands the intricate dance of algorithms and the art of captivating content. He's the guy who can make a product trend on Twitter, turn a YouTube video into a sensation overnight, and transform a brand into an online powerhouse.`,
    social: {
      facebook: "",
      twitter: "",
      instagram: "",
      linkedin: "",
      github: "",
    },
  }
  // {
  //   id: 6,
  //   username: "manish",
  //   name: "Manish Sharma",
  //   link: RoutingLinks.website.teamMembers.manish,
  //   position: "Legal Advisor",
  //   img: bettercallmanish,
  //   bgColor: "#d6f7de",
  //   description:
  //     "Manish is an entrepreneur, steering his own legal practice. His entrepreneurial spirit drives him to shape the legal landscape and redefine how legal services are provided. As a lawyer, he has honed his expertise in the intricacies of IT agreements, cases, and legal filings. His legal acumen and dedication to the field have set him apart as a legal visionary.",
  //   social: {
  //     facebook: "",
  //     twitter: "",
  //     instagram: "",
  //     linkedin: "",
  //     github: "",
  //   },
  // },
];
export default TeamMembersInfo;
