import React from "react";
import { useQuery } from "react-query";
import PostApis from "src/api/Posts";
import LoadingScreen from "src/components/LoadingScreen";
import PostList from "src/components/Posts/PostList";

const Posts = () => {
  const {
    status,
    error,
    data: posts
  } = useQuery({
    queryKey: ["posts"],
    queryFn: PostApis.getPosts
  });

  if (status === "loading") return <LoadingScreen />;
  if (status === "error") return <h1>Error Screen {JSON.stringify(error)}</h1>;

  return (
    <>
      <h1>For all posts /posts/all</h1>
      <PostList posts={posts.posts} />
    </>
  );
};

export default Posts;