import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <>
      <div className="utility-page-wrap not-found">
        <div className="container-default w-container">
          <div
            data-w-id="619efe17469a19c94a600b1500000000000b"
            className="utility-page-content not-found w-form md:style-1"
          >
            <div className="shape-wrapper">
              <div className="shape size-124px top-left">
                <img
                  src="https://assets.website-files.com/63dbcee746674134a00f2de6/63dd637c17816147a96b9cd1_wrench-cloudly-x-webflow-templates.png"
                  alt=""
                  className="moving-item-one"
                />
              </div>
              <div className="shape size-144px bottom-left">
                <img
                  src="https://assets.website-files.com/63dbcee746674134a00f2de6/63dd63b8abb52b1772d8a2a2_toolbox-cloudly-x-webflow-templates.png"
                  alt=""
                  className="blur-5px moving-item-two"
                />
              </div>
              <div className="shape size-166px top-right">
                <img
                  src="https://assets.website-files.com/63dbcee746674134a00f2de6/63dd640639a2b23e7ef675da_no-entry-cloudly-x-webflow-templates.png"
                  alt=""
                  className="moving-item-two"
                />
              </div>
              <div className="shape size-132px bottom-right">
                <img
                  src="https://assets.website-files.com/63dbcee746674134a00f2de6/63dd643bcde3840fa0773a78_screwdriver-cloudly-x-webflow-templates.png"
                  alt=""
                  className="blur-5px moving-item-two"
                />
              </div>
            </div>
            <div
              data-w-id="69e3412a-8708-0a1b-964e-3d826871f405"
              className="z-index-1 md:style-1"
            >
              <div className="flex-horizontal">
                <img
                  src="https://assets.website-files.com/63dbcee746674134a00f2de6/63dd619bbb1048c423f57f78_not-found-cloudly-x-webflow-templates.png"
                  srcSet="https://assets.website-files.com/63dbcee746674134a00f2de6/63dd619bbb1048c423f57f78_not-found-cloudly-x-webflow-templates-p-500.png 500w, https://assets.website-files.com/63dbcee746674134a00f2de6/63dd619bbb1048c423f57f78_not-found-cloudly-x-webflow-templates-p-800.png 800w, https://assets.website-files.com/63dbcee746674134a00f2de6/63dd619bbb1048c423f57f78_not-found-cloudly-x-webflow-templates-p-1080.png 1080w, https://assets.website-files.com/63dbcee746674134a00f2de6/63dd619bbb1048c423f57f78_not-found-cloudly-x-webflow-templates.png 1086w"
                  sizes="(max-width: 479px) 280px, (max-width: 767px) 300px, 542px"
                  alt=""
                  className="not-found---logo"
                />
              </div>
              <h1 className="display-1">Oops. Page not found</h1>
              <div className="inner-container _754px center">
                <p className="mg-bottom-48px">
                  Condimentum integer netus mauris tristique dui ridiculus netus
                  metus, augue accumsan, sed adipiscing habitasse mauris consec
                  litum asic suspendisse malesuada.
                </p>
              </div>
              <div className="buttons-row center">
                <Link to="/home" className="btn-primary button-row w-button">
                  Go to homepage
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotFound;
